;(function(){
  "use strict"

  window.Market.Tovar = (function() {
    var instance
    var create_instance = function() {
      var options = window.Market.Tovar.options
      var place = $(window.Market.Tovar.options.place_id)
      if (!place.length) {
        console.log('Не обнаружено место товара.')
        return null
      }


      var instance = new Object({
        place: place,
        tovar_id: null,
        options: options,
        enter_timeout_id: null,
        leave_timeout_id: null,
        initial_info: null,
        is_hover_color_img_exist: {},

        activate: function() {
          this.place = $(this.options.place_id)
          this.tovar_id = this.place.data('id')
          this.initial_info = null
          this.is_hover_color_img_exist = {}
          this.create_initial_info()
          this.activate_slider()
          this.activate_button_show_modal_buy()
          this.activate_button_add_to_cart()
          // this.activate_color_hover()
          this.activate_second_filter_hover()
          this.activate_related_liner()
          this.activate_open_discounts()
          this.activate_tabs()
        },

        activate_slider: function() {
          // this.activate_gallery()
          // window.activate_slider3()
          window.activate_slider4()
          this.activate_slider_thumbnails()
          this.activate_slider_views()
        },

        // Активация вкладок и другие БЛ:
        // Активируется вкладка Описание если есть эта вкладка и установлен флаг
        // на показ Описания.
        activate_tabs: function() {
          // Кнопки компонента табы.
          var tabs_buttons = $('#tab > ul > li')
          if (!tabs_buttons.length) {
            return null
          }
          // Компонент табы.
          var tabs = $("#tab")
          var default_content = tabs.data("default_content")

          // При переходе назад по истории браузера:
          if (tabs.hasClass("ui-tabs")) {
            tabs.find("> ul > li").removeClass("ui-tabs-active")
          }

          // Пытаемся определить индекс вкладки Описание.
          var description_index = null
          for (var i=0, l=tabs_buttons.length; i<l; i++) {
            var tab = $(tabs_buttons[i])
            if (tab.text() == 'Описание') {
              description_index = tab.index()
            }
          }

          // Определяем показываемую вкладку (первая или Описание если оно есть).
          var active_index = 0
          if (default_content == "description" && description_index != null) {
            active_index = description_index
          }

          // Активируем табы с нужной вкладкой.
          tabs.tabs({
            active: active_index
          })

          // Активация кнопки перехода от сокращенного списка характеристик к
          // характеристикам в табах
          var open_characteristics = this.place.find('.js_open_characteristics')
          if (open_characteristics.length == 1) {
            open_characteristics.on("click", function(event) {
              var index = $('#tab > ul > li[data-characteristics]').index()
              $('#tab').tabs("option", "active", index)
              window.scrollTo(window.pageXOffset, $("#tab").offset().top)
            })
          }
        },

        // Активация слайдера с миниатюрами картинок и видео.
        // Проблема была в том, что при инициализации добавляется дополнительный
        // dom (навигация, линейка слайдера и т.п.), после перехода на новую
        // страницу и перехода назад по истории браузера происходит
        // переинициализация слайдера уже на изменённом DOM, а плагин такого не
        // умеет (инициализироваться на изменённом DOM).
        activate_slider_thumbnails: function(argument) {
          var slider_thumbnails = this.place.find(this.options.selector.slider_thumbnails)
          var slider_views = this.place.find(this.options.selector.slider_views)
          if (slider_thumbnails.length == 0) {
            return null
          }
          // Возвращаем исходную структуру DOM, если инициализация уже
          // происходила.
          if (slider_thumbnails.hasClass("slick-initialized")) {
            slider_thumbnails.children(".slick-arrow").remove()
            slider_thumbnails.find(".slider_thumbnail").appendTo(slider_thumbnails)
            slider_thumbnails.children(".slick-list").remove()
            slider_thumbnails.removeClass("slick-initialized").removeClass("slick-slider").removeClass("slick-vertical")
          }
          slider_thumbnails.slick({
            vertical: true,
            verticalSwiping: true,
            slidesToShow: 2,
            // variableWidth: true,
            infinite: false,
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            speed: 100,
            // autoplay:true,
            // autoplaySpeed: 1000,
          })
          slider_thumbnails.on('afterChange', function(event, slick, currentSlide, nextSlide) {
            slider_views.children(".slider_view").eq(currentSlide).addClass("current").siblings(".slider_view").removeClass("current")
          });
        },

        activate_slider_views: function(argument) {
          var slider = this.place.find(this.options.selector.slider_views)
          var images = slider.find("a")
          // При закрытии всплывающего окна обновлялся урл на исходное
          // состояние (без #image-N) и страница перезагружается, решение:
          $.fancybox.defaults.hash = false;
          images.fancybox({
            // Необходимо для переинициализации FB при обновлении turbolinks-ом
            // тега body
            parent: 'body',
            loop: false,
            helpers: {
              title : {
                type : 'inside'
              },
              buttons : {}
            },
            buttons: [
              "zoom",
              //"share",
              // "slideShow",
              //"fullScreen",
              //"download",
              // "thumbs",
              "close"
            ],
          })
        },

        // Активирует нажатия на изображения формата "big".
        activate_gallery: function() {
          var gallery = this.place.find(this.options.selector.gallery)
          var images = gallery.find("a")
          images.fancybox({
            // Необходимо для переинициализации FB при обновлении turbolinks-ом
            // тега body
            parent: 'body',
            loop: false,
            helpers: {
              title : {
                type : 'inside'
              },
              buttons : {}
            },
            buttons: [
              "zoom",
              //"share",
              // "slideShow",
              //"fullScreen",
              //"download",
              // "thumbs",
              "close"
            ],
            // При закрытии всплывающего окна обновлялся урл на исходное
            // состояние (без #image-N) и страница перезагружается
            // hash : null,
          })
        },

        // Смена информации о товаре при наведении на цвета
        activate_color_hover: function() {
          //this.generate_cache_color_hover()
          this.place.on(
            'mouseenter',
            this.options.color_selector,
            {instance:this},
            this.color_mouseenter_handler
          ).on(
            'mouseleave',
            this.options.color_selector,
            {instance:this},
            this.color_mouseleave_handler
          )
        },

        // Активируем все связанные с продуктом (в табах) лайнеры продуктов.
        activate_related_liner: function() {
          var liners = this.place.parent().find("#tab .products.related .liner")
          if (liners.length == 0) {
            return null
          }
          liners.each(function(i, dom) {
            new window.Market.Liner(
              $(dom),
              {
                callback_scroll: window.lazyload_trigger,
                window_division: 5,
                division_width: 214
              }
            ).activate()
          })
        },

        activate_second_filter_hover: function(){
          this.place.on(
            'mouseenter',
            this.options.second_filter_selector,
            this.second_filter_mouseenter_handler
            ).on(
            'mouseleave',
            this.options.second_filter_selector,
            this.second_filter_mouseleave_handler
            )
        },

        create_initial_info: function() {
          var color = $(this.options.slected_color_selector)
          var colors_box = color.parent()
          var color_name = colors_box.data('initial_color')
          var info = {}

          info.color_name = color_name
          info.articul = instance.get_articul()
          info.price = this.place.find(this.options.price_selector).html()
          info.amount = this.place.find(this.options.amount_selector).html()
          info.web_name = instance.place.find(instance.options.web_name_selector).data('initial_c1name')

          var img_src = instance.place.find('.slider4 .active > img').attr('src')
          if (img_src!=null) {
            img_src = img_src.replace('thumbnail', 'view')
          }
          info.img_src = img_src

          instance.initial_info =  _.extend({}, info)
        },

        color_mouseenter_handler: function(event) {
          var instance = event.data.instance
          if (instance.leave_timeout_id!=null) {
            clearTimeout(instance.leave_timeout_id)
            instance.leave_timeout_id = null
          }

          var info = {}
          var state_of_product = null
          var color = $(this)
          var color_name = color.attr('title')
          var color_url = color.data('color_url')
          var second_filter_size = $('#product .boxs .boxs_b')
          var second_filter_radio_act = second_filter_size.find('a.radio_act').text()
          if (color.hasClass('graize')) {
              state_of_product = second_filter_radio_act
              info.state_of_product = state_of_product
          }

          info.color_name = color_name

          var articul = color.data('color_articul')
          info.articul = articul

          var web_name = window.web_name_articul[articul]||''
          info.web_name = web_name

          info.img_src = color_url

          var promise_get_trader = new Promise(function(resolve, reject) {
            $.ajax({
              type: 'post',
              url: '/trader/' + articul,
              data: {phil: $.cookie('phil')},
              async: true,
              dataType: 'json',
              success: function(data, textStatus, jqXHR) {
                resolve(data)
              },
              error: function(jqXHR, textStatus, errorThrown) {
                var data = [textStatus, errorThrown]
                reject(data)
              }
            })
          })

          promise_get_trader.then(
            function(resolve_data) {
              info.price = resolve_data[4]
              info.amount = resolve_data[5]
              var enter_timeout_id = setTimeout(function() { 
                instance.ui_set_info_from_color(info)
              }, instance.options.enter_timeout_delay)
              instance.enter_timeout_id = enter_timeout_id
            },
            function(reject_data) {
              console.log('reject_data: ', reject_data)
            }
          )

        },

        color_mouseleave_handler: function(event) {
          var instance = event.data.instance
          if (instance.enter_timeout_id!=null) {
            clearTimeout(instance.enter_timeout_id)
            instance.enter_timeout_id = null
          }
          $('#product .boxs .if_no_color').empty()
          var info = instance.initial_info
          var leave_timeout_id = setTimeout(function() {
            instance.ui_set_info_from_color(info)
          }, instance.options.leave_timeout_delay)
          instance.leave_timeout_id = leave_timeout_id
        },

        second_filter_mouseleave_handler: function(event){
             var second_filter = $(this)
             var default_second_filter = $('#product .boxs .if_no_second_filter')
             default_second_filter.empty()
         },

        second_filter_mouseenter_handler: function(event){
          var second_filter = $(this)
          var first_filter_color = $('#product .boxs .color_box')
          var first_filter_radio_act = first_filter_color.find('a.radio_act').attr('title')
          var default_second_filter = $('#product .boxs .if_no_second_filter')
          if (second_filter.hasClass('graize')) {
            if (first_filter_radio_act != undefined){
              default_second_filter.text('недоступно в ' + ' ' + first_filter_radio_act)
            } else {
              default_second_filter.text('недоступно в ' + ' ' + $(this).text())
            }
          }
  
        },

        get_articul: function() {
          return this.place.data('articul')||''
        },
        activate_button_add_to_cart: function() {
          this.place.on(
            'click',
            this.options.button_add_to_cart,
            {instance:this},
            this.add_to_cart_handler
          )
        },
        // Активируем нажатие на кнопку "перехода в таб скидок"
        activate_open_discounts: function() {
          var button = this.place.find(".js_open_discounts")
          if (button.length == 0) {
            return null
          }
          var tabs = this.place.parent().find("#tab")
          button.on("click", function(event) {
            var tabs_buttons = tabs.children("ul").find("> li")
            var tabs_discounts_button = tabs.find("#discounts_and_markups_tab")
            if (tabs_discounts_button.length == 0 || tabs_buttons.length == 0) {
              return null
            }
            var index = tabs_buttons.index(tabs_discounts_button)
            tabs.tabs("option", "active", index)
            window.scrollTo({
              top: tabs_discounts_button.offset().top,
              behavior: "smooth"
            })
          })
        },
        add_to_cart_handler: function(event) {
          if (!window.uncached.env.seo_counters_off) {
            window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'ADD_TO_CART_HANDLER')
          }
          var instance = event.data.instance
          var button = $(this)
          if (button.parents("#modal-group").length == 1) {
            var tovar = button.parents("div#product").first()
            var articul = tovar.find(".art.blocks").text()
            var count = tovar.find(".js_buy_wrapper input.int").val()
          } else {
            var tovar = button.parents(instance.options.tovar_selector)
            var articul = tovar.find('.art').first().text().split(/\n|\s+/).filter(function(e){return e != ''})[1]
            var input = tovar.find(instance.options.amount_input)
            var count = input.val()
          }
          button.removeClass("success_action")
          button.prop("disabled", true)
          button.addClass("loading")
          window.app.cart_add(articul, count).then(
            function(data) {
              if (Object.prototype.toString.call(data.list) === '[object Array]') {
                var text = "в корзине "
                for (var i = 0, l = data.list.length; i < l; i++) {
                  var item = data.list[i]
                  if (item.articul == articul) {
                    text+= item.count.toString() + " " + item.count.declension("товаров,товар,товара")
                    break
                  }
                }
                for (var i = 0, l = data.offer_products.length; i < l; i++) {
                  var item = data.offer_products[i]
                  if (item.offer_product.code == articul) {
                    text+= item.offer_product_rel.count.toString() + " " + item.offer_product_rel.count.declension("товаров,товар,товара")
                    break
                  }
                }
                button.attr("data-success_action", text)
              }
              button.removeClass("loading")
              button.prop("disabled", false)
              button.addClass("success_action")
            },
            function(reason) {
              button.removeClass("loading")
              button.prop("disabled", false)
            }
          )
        },
        activate_button_show_modal_buy: function() {
          this.place.on(
            'click',
            this.options.button_show_modal_by_selector,
            {instance:this},
            this.show_modal_by_handler
          )
        },

        show_modal_by_handler: function(event) {
          var instance = event.data.instance
          var button = $(this)
          var tovar = button.parents(instance.options.place_id)
          var subdivision_place = tovar.attr('data-subdivision')
          var subdivisions_phone =  subdivisions.Техник.phone_number
          var subdivisions_email =  subdivisions.Техник.email
          // Очистить
          window.Market.modal.buy.clean()
          // Взять
          var articul = instance.place.data('articul')
          // В некоторых ситуация articul = '', исправление:
          if (!articul) {
            var another_articul = instance.place.find('.right .art').text()
          }
          var existing_articul = articul || another_articul
          var name = tovar.find(".js_c1name").text()
          var affiliate_phonenumber = $('#header .phone').first().text() || ''
          var phonenumber = subdivision_place == 'Техник' ? subdivisions_phone : affiliate_phonenumber
          var affiliate_email = window.cache.affiliate[$.cookie('phil')]["email"]
          var email = subdivision_place == 'Техник' ? subdivisions_email : affiliate_email
          // Вставить
          window.Market.modal.buy.replace({
            articul: articul,
            name: name,
            phonenumber: phonenumber,
            email: email
          })
          window.Market.modal.buy.replace({articul:existing_articul, phonenumber: phonenumber})
          // Показать
          window.Market.modal.buy.show()
        },

        format_metric: function(price_metric) {
          var metric = price_metric||''
          var metric_symbol = ' ₽/' + metric
          return metric_symbol
        },

        format_price: function(price_amount) {
          var price_rub = ''
          var price_pennie = ''
          if (Object.prototype.toString.call(price_amount) === '[object Number]') {
            var amount = price_amount
          } else if (Object.prototype.toString.call(price_amount) === '[object String]') {
            var amount = parseFloat(price_amount.replace(/\s/g, '').replace(/,/g, '.'))
          }
          if (!amount||amount==NaN) {
            return price
          }
          var ui_amount_pennie = window.numberWithSpaces(amount.toFixed(2)).pennie
          var ui_amount_rub = window.numberWithSpaces(amount.toFixed(2)).rub
          price_rub = ui_amount_rub 
          price_pennie = ui_amount_pennie 
          return {
            rub: price_rub,
            pennie: price_pennie
          }
        },

        format_amount: function(amount_amount, amount_metric) {
          amount_metric = amount_metric || ''
          amount_amount = amount_amount || 0
          if (Object.prototype.toString.call(amount_amount) === '[object Number]') {
            amount_amount = amount_amount
          } else if (Object.prototype.toString.call(amount_amount) === '[object String]') {
            amount_amount = parseFloat(amount_amount.replace(/,/g, '.'))
          }
          if (!amount_amount||amount_amount==NaN) {
            amount_amount = 0
          }
          amount_amount = window.numberWithSpaces(amount_amount.toFixed(0)).rub
          if (amount_amount == 0) {
            var amount = ' в наличии по сети' 
          } else {
             amount = 'наличие: ' + amount_amount + ' ' + amount_metric
          }
          return amount
        },


        // Функционал взаимодействующий с пользовательским интерфейсом
        ui_set_articul: function(articul) {
          this.place.find('.art').text('артикул: ' + articul)
        },
        ui_set_metric: function(metric) {
          this.place.find(instance.options.metric_selector).text(metric)
        },
        ui_set_price: function(price) {
          this.place.find(instance.options.price_selector).html(price)
        },

        ui_set_amount: function(amount) {
          this.place.find(instance.options.amount_selector).html(amount)
        },

        ui_set_buy: function(buy) {
          this.place.find(instance.options.buy_selector).html(buy)
        },

        ui_set_color_name: function(color_name) {
          this.place.find(this.options.color_name_selector).text('Цвет: ' + color_name)
        },

        ui_set_state_of_product: function(state_of_product) {
          this.place.find('.boxs .if_no_color').text('недоступен в' + ' ' + state_of_product)
        },

        ui_set_web_name: function(web_name) {
          this.place.find(this.options.web_name_selector).text(web_name)
        },

        ui_set_info_from_color: function(info) {
          if (info==null) {
            return null
          }
          if (info.articul!=null) {
            this.ui_set_articul(info.articul)
          }
          if(info.state_of_product!=null) {
            this.ui_set_state_of_product(info.state_of_product)
          }
          if (info.price!=null) {
            this.ui_set_price(info.price)
          }
          if (info.metric!=null) {
            this.ui_set_metric(info.metric)
          }
          if (info.amount!=null) {
            this.ui_set_amount(info.amount)
          }
          if (info.color_name!=null) {
            this.ui_set_color_name(info.color_name)
          }
          if (info.web_name != null) {
            this.ui_set_web_name(info.web_name)
          }
        }


      })
      return instance
    }
    return {
      options: {
        place_id: '#product',
        button_add_to_cart: '.js_button_add_to_cart',
        amount_input: '.js_buy_wrapper input.int',
        button_show_modal_by_selector: '.js_under_the_order',
        color_selector: '.color_box a.radio',
        slected_color_selector: '.color_box a.radio.radio_act',
        second_filter_selector : '.boxs_b a.radio',
        default_color : '#product .boxs .if_no_color',
        color_name_selector: '.js_color_name',
        price_selector: '.js_price_wrapper',
        price_rub_selector: '.js_price .rub',
        price_pennie_selector: '.js_price .kop',
        metric_selector: '.js_price .symbol',
        amount_selector: '.js_amount_wrapper',
        buy_selector: '.js_buy_wrapper',
        web_name_selector: '.js_c1name',
        group_filters_selector: '.js_group_filters',
        leave_timeout_delay: 190,
        enter_timeout_delay: 50,
        selector: {
          gallery: ".gallery",
          slider_thumbnails: ".slider_thumbnails",
          slider_views: ".slider_views",
        }
      },
      get_instance: function() {
        if (instance==null) {
          instance = create_instance()
        }
        return instance
      },
      activate: function() {
        var instance = this.get_instance()
        if (!instance) {
          return null
        }
        return instance.activate()
      }
    }
  })()

})();
